
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'employee_bank1_limit';
    this._info = 'info.bank1_limit';
    this._need = true;
  }

  get condition() {
    return this.object.is_bank2 == 1;
  }

  get is_change() {
    return this.object.bank1_limit !== this.old_object.bank1_limit;
  }
}
