
  import { Component, Mixins } from "vue-property-decorator";
  import mixinEmployeeEachPart from "@/mixins/mixinEmployeeEachPart";
  import { Inject } from "@/library/provide";
  import LItem from "@/components/l/Item.vue";
  import EmployeeeachSelfBankBankdisp from "@/components/employeeeach/self/bank/bankdisp.vue";

  @Component({
    components: {
      LItem,
      EmployeeeachSelfBankBankdisp,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEachPart) {
    created() {
      this._title = "employee_bank1";
    }

    get condition() {
      return this.object.is_bank1 == 1 && !this.is_edit;
    }

    get is_change() {
      return (
        this.object.bank1_name !== this.old_object.bank1_name ||
        this.object.bank1_code !== this.old_object.bank1_code ||
        this.object.branch1_name !== this.old_object.branch1_name ||
        this.object.branch1_code !== this.old_object.branch1_code ||
        this.object.bank1_type !== this.old_object.bank1_type ||
        this.object.bank1_num !== this.old_object.bank1_num ||
        this.object.bank1_holder !== this.old_object.bank1_holder
      );
    }
  }
