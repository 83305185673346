
import { Component, Mixins, Vue } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FBank from "@/components/f/Bank.vue";

@Component({
  components: {
    LItem,
    FBank,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'bank_name';
    this._need = true;
  }
}
