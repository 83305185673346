import { render, staticRenderFns } from "./banknum2.vue?vue&type=template&id=0b8cb991&scoped=true&lang=pug"
import script from "./banknum2.vue?vue&type=script&lang=ts"
export * from "./banknum2.vue?vue&type=script&lang=ts"
import style0 from "./banknum2.vue?vue&type=style&index=0&id=0b8cb991&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b8cb991",
  null
  
)

export default component.exports