
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import { Provide } from '@/library/provide';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'bank_num';
    this._need = true;
  }

  @Provide('info')
  get use_info() {
   return Number(this.object.bank1_code) == 9900 ? this.$t('info.yucho_bank_num') : undefined;
  }
}
