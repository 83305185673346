
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import { Inject } from '@/library/provide';
import LItem from "@/components/l/Item.vue";
import EmployeeeachSelfBankBankdisp from "@/components/employeeeach/self/bank/bankdisp.vue";

@Component({
  components: {
    LItem,
    EmployeeeachSelfBankBankdisp,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'employee_bank2';
  }

  get condition() {
    return this.object.is_bank2 == 1 && !this.is_edit;
  }

  get is_change() {
    return this.object.bank2_name !== this.old_object.bank2_name || 
      this.object.bank2_code !== this.old_object.bank2_code || 
      this.object.branch2_name !== this.old_object.branch2_name || 
      this.object.branch2_code !== this.old_object.branch2_code || 
      this.object.bank2_type !== this.old_object.bank2_type || 
      this.object.bank2_num !== this.old_object.bank2_num || 
      this.object.bank2_holder !== this.old_object.bank2_holder;
  }
}
