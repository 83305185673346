
import { Component, Mixins, Watch } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import { Provide } from '@/library/provide';
import LItem from "@/components/l/Item.vue";
import FBranch from "@/components/f/Branch.vue";

@Component({
  components: {
    LItem,
    FBranch,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  @Provide('title')
  get use_title() {
    return Number(this.object.bank1_code) == 9900 ? this.$t('yucho_branch_name') : this.$t('bank_branch_name');
  }

  created() {
    this._need = true;
  }
}
